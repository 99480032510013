import React, {useContext, useEffect, useState} from 'react'
import {LykioContext} from "../routing/LykioAuthorizedRoute";
import Loading from "../common/Loading";
import {Autocomplete, Box, Container, Grid, TextField, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import axios from "axios";
import {
    AUTHORIZED_URL_PREFIX,
    AxiosConfig,
    EDUCATIONAL_USER_MANAGEMENT_WITHOUTPARAM_URL,
    GET_ALL_TENANTS,
    GET_GROUPS_PER_TENANT,
    GET_USERS_PER_GROUP,
    GROUPS_URL, ROLE_ADMIN, ROLE_TEACHER,
    ROLE_UBER, ROLE_USER,
    USER_MANAGEMENT_WITHOUTPARAM_URL
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import UsersTable from "../educational/UsersTable";
import SupportEmail from '../common/SupportEmail';

const UserManagement = () => {

    const [show, setShow] = useState(false);
    const context = useContext(LykioContext);
    const userDetails = context.userDetails;
    const userPermissions = context.userPermissions;
    const tenantProperties = context.tenantProperties;
    const userRole = localStorage.getItem('userRole');
    const localCanAssignAdmin = localStorage.getItem('localca');
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    localCanAssignAdmin===null && (localStorage.setItem('localca', userPermissions ? !userPermissions.includes('teacherusermanagement') : false))
    const assignAdminRole = localStorage.getItem('localca')==='true' ? true : false;
    const [ isLoading, setIsLoading ] = useState(true);
    const [ users, setUsers ] = useState([]);

    const [ groups, setGroups ] = useState([]);
    const [ selectedGroup, setSelectedGroup ] = useState();

    const [tenants, setTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState();
    const [classOwner, setClassOwner] = useState();

    const fetchGroupsData = async () => {
        try {

            setIsLoading(true);
            const { data } = await axios.get(
                `${AUTHORIZED_URL_PREFIX}${GROUPS_URL}`,
                AxiosConfig()
            );

            console.log(data.groups)
             if(data.success) {
                 if(userRole === ROLE_TEACHER) {
                     let teacherGroups = data.groups.filter((singleGroup) => singleGroup.owner === loggedUser.id )
                     setGroups(teacherGroups)
                     setSelectedGroup(teacherGroups.length > 0 ? teacherGroups[0] : '');
                 } else {
                     setGroups(data.groups);
                     setSelectedGroup(data.groups.length > 0 ? data.groups[0] : '');
                 }
                 setIsLoading(false);

             }

            if (!data.success) {
                notify(pleaseRefreshError);
            }
        } catch (error) {
            console.error(error);
            notify(pleaseRefreshError);
        }
    };

    const fetchUberTenants = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(
                `${AUTHORIZED_URL_PREFIX}${GET_ALL_TENANTS}`,
                AxiosConfig()
            );
            setTenants(data.tenants)
            setSelectedTenant(data.tenants.length > 0 && data.tenants[0])
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            notify(pleaseRefreshError)
        }
    }

    useEffect(
        () => {
            if(userRole === ROLE_UBER) {
                fetchUberTenants()
            } else {
                fetchGroupsData();
            }
        },
        []
    );

    useEffect( async () => {
        if(selectedTenant) {
            if (userRole === ROLE_UBER) {
                const { data } = await axios.post(
                    `${AUTHORIZED_URL_PREFIX}${GET_GROUPS_PER_TENANT}`,
                    {tenantId: selectedTenant._id},
                    AxiosConfig()
                );
                setSelectedGroup(data.groups.length > 0 ? data.groups[0] : '');
                setGroups(data.groups)
            }
        }

    },[selectedTenant])

    useEffect(async () => {
        try {
            if (userRole !== ROLE_UBER ) {
                setIsLoading(true);
                if (selectedGroup ){
                    const { data } = await axios.post(
                        `${AUTHORIZED_URL_PREFIX}${USER_MANAGEMENT_WITHOUTPARAM_URL}`,
                        { groupReference: selectedGroup.reference },
                        AxiosConfig()
                    );
                    setUsers(data.users.filter(u => u.id !== loggedUser.id));


                }
                setIsLoading(false);
            } else {
                setIsLoading(true);
                if (selectedGroup ){
                    const { data } = await axios.post(
                        `${AUTHORIZED_URL_PREFIX}${GET_USERS_PER_GROUP}`,
                        { groupReference: selectedGroup.reference, tenantId: selectedTenant._id },
                        AxiosConfig()
                    );
                    setUsers(data.users.filter(u => u.id !== loggedUser.id));
                    setClassOwner(data.owner)
                }
                setIsLoading(false);
            }


        } catch (e) {
            console.log(e)
        }
    }, [selectedGroup])
    return (
        <div>
            <Container>
                {isLoading ? (
                    <Loading />
                ) : (
                    <Box bgcolor={'white'} m={4} p={3} sx={{borderRadius: 2}}>
                        <Typography variant="h5" gutterBottom component="div">{t('School Management')}</Typography>
                        {userRole === ROLE_ADMIN && selectedGroup && (
                            <>
                                <Autocomplete
                                    sx={{ width: 300, paddingTop: 5 }}
                                    size={'small'}
                                    autoHighlight
                                    options={groups}
                                    getOptionLabel={(option) => option.name ? option.name : ''}
                                    value={selectedGroup}
                                    onChange={(event, val) => {
                                        if(val !== null) {
                                            setSelectedGroup(val)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select a Class" />}

                                />

                                {users.length > 0 ? (
                                    <div className="lykiocss-material-table my-4">
                                        {/*<h5>{selectedGroup===':groupReference' ? tenantProperties.name : selectedGroup} {users && `(${users.length} users)`}</h5>*/}
                                        <UsersTable users={users} assignAdminRole={assignAdminRole}/>
                                    </div>
                                ) : (
                                    <div className="">
                                        {t('No entries for')}
                                    </div>
                                )}
                            </>
                        )}
                        {userRole === ROLE_UBER && selectedGroup && (
                            <>
                                <Autocomplete
                                    sx={{ width: 300, paddingTop: 5 }}
                                    size={'small'}
                                    options={tenants}
                                    getOptionLabel={(option) => option.name ? option.name : ''}
                                    // defaultValue={tenants.find(v => v)}
                                    value={selectedTenant}
                                    // isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(event, val) => {
                                        if(val !== null) {
                                            setSelectedTenant(val)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select a School" />}
                                    />
                                    {selectedTenant && (
                                        <Autocomplete
                                            sx={{ width: 300, paddingTop: 5 }}
                                            size={'small'}
                                            autoHighlight
                                            options={groups}
                                            getOptionLabel={(option) => option.name ? option.name : ''}
                                            value={selectedGroup}
                                            onChange={(event, val) => {
                                                if(val !== null) {
                                                    setSelectedGroup(val)
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Select a Class" />}

                                        />

                                    )}
                                {users && users.length > 0 ? (
                                    <div className="lykiocss-material-table my-4">
                                        <h5>{selectedGroup ? selectedTenant.name : selectedGroup.name} {users && `(${users.length} users)`}</h5>
                                        <h5>{classOwner ? `Class Owner: ${classOwner.fullName }`: ''} </h5>
                                        <UsersTable users={users} assignAdminRole={assignAdminRole}/>
                                    </div>
                                ) : (
                                    <div className="">
                                        {t('No entries for')}
                                    </div>
                                )}
                            </>
                        )}
                        {userRole === ROLE_TEACHER && selectedGroup && (
                            <>
                                <Autocomplete
                                    sx={{ width: 300, paddingTop: 5 }}
                                    size={'small'}
                                    autoHighlight
                                    options={groups}
                                    getOptionLabel={(option) => option.name ? option.name : ''}
                                    value={selectedGroup}
                                    onChange={(event, val) => {
                                        if(val !== null) {
                                            setSelectedGroup(val)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select a Class" />}

                                />

                                {users.length > 0 ? (
                                    <div className="lykiocss-material-table my-4">
                                        {/*<h5>{selectedGroup===':groupReference' ? tenantProperties.name : selectedGroup} {users && `(${users.length} users)`}</h5>*/}
                                        <UsersTable users={users} assignAdminRole={assignAdminRole}/>
                                    </div>
                                ) : (
                                    <div className="">
                                        {t('No entries for')}
                                    </div>
                                )}
                            </>
                        )}




                    </Box>
                )}
            </Container>
            <SupportEmail/>
        </div>
    );
}


export default  UserManagement;