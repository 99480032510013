import React, {useContext, useEffect, useState, useRef} from 'react'
import {Autocomplete, Box, Container, TextField, Typography} from "@mui/material";
import Loading from "../common/Loading";
import t from "../../Utils/Translation";
import {
    AUTHORIZED_URL_PREFIX, AxiosConfig, GET_USERS_PER_GROUP, GROUPS_URL,
    ROLE_ADMIN,
    ROLE_TEACHER,
    ROLE_UBER, TT_REPORTING,
    USER_MANAGEMENT_WITHOUTPARAM_URL
} from "../../Utils/Constants";
import UsersTable from "./TTReportingUsersTable";
import {LykioContext} from "../routing/LykioAuthorizedRoute";
import axios from "axios";
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import ExportReactCSV from "../common/ExportReactCSV";
import friendlyDate from "../../Utils/FriendlyDate";
import TextSnippetSharpIcon from "@mui/icons-material/TextSnippetSharp";
import {CSVLink} from "react-csv";
import LykioButton from "../common/LykioButton";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PrintSharpIcon from '@mui/icons-material/PrintSharp';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, Title,BarElement, CategoryScale, LinearScale,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import { useReactToPrint } from 'react-to-print';
import SupportEmail from '../common/SupportEmail';

const TTReporting = () => {

    const context = useContext(LykioContext);
    const userPermissions = context.userPermissions;
    const userRole = localStorage.getItem('userRole')
    const localCanAssignAdmin = localStorage.getItem('localca');
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    localCanAssignAdmin===null && (localStorage.setItem('localca', userPermissions ? !userPermissions.includes('teacherusermanagement') : false))
    const assignAdminRole = localStorage.getItem('localca')==='true' ? true : false;
    const [ isLoading, setIsLoading ] = useState(true);
    const [ users, setUsers ] = useState([]);

    const [ groups, setGroups ] = useState([]);
    const [ selectedGroup, setSelectedGroup ] = useState();

    const [excelTable, setExcelTable] = useState([]);
    const [csvFileName, setCsvFileName] = useState('lykio-usage-report')

    const [classStats,setClassStats] = useState();


    ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels,  CategoryScale, LinearScale, BarElement);

    const fetchGroupsData = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(
                `${AUTHORIZED_URL_PREFIX}${GROUPS_URL}`,
                AxiosConfig()
            );
            console.log(data.groups)
            if(data.success) {
                if(userRole === ROLE_TEACHER) {
                    let teacherGroups = data.groups.filter((singleGroup) => singleGroup.owner === loggedUser.id )
                    setGroups(teacherGroups)
                    setSelectedGroup(teacherGroups.length > 0 ? teacherGroups[0] : '');
                } else {
                    setGroups(data.groups);
                    setSelectedGroup(data.groups.length > 0 ? data.groups[0] : '');
                }
                setIsLoading(false);
            }

            if (!data.success) {
                notify(pleaseRefreshError);
            }
        } catch (error) {
            console.error(error);
            notify(pleaseRefreshError);
        }
    };

    useEffect(() => {
            fetchGroupsData();
        },[]);

    useEffect(async() => {
        setIsLoading(true);
        if (selectedGroup ){
            const { data } = await axios.post(
                `${AUTHORIZED_URL_PREFIX}${TT_REPORTING}`,
                { groupReference: selectedGroup.reference },
                AxiosConfig()
            );
            if(data.success) {
                console.log("Setting the data!!!");
                setUsers(data.users);
                console.log(data)
                setClassStats(data.classStats);
                setCsvFileName(`tt-report_${friendlyDate(new Date())}-${selectedGroup && selectedGroup.name}`)
                let excel = [];
                if(data.users.length > 0) {

                    data.users.map(user => {
                        excel.push({
                            'Full Name': user.fullName,
                            Email: user.email,
                            'Workbook Attempts': user.wb_attempts,
                            'Workbook Average Score': user.wb_averageScore,
                            'Game Progress' : user.g_progress,
                            'Game Total Score' : user.g_totalScore,
                            'Game Vocabulary' : user.g_vocabulary,
                            'Game Grammar': user.g_grammar,
                            'Game Reading': user.g_reading,
                            'Game Use of English': user.g_useofenglish
                        })
                    })
                }


                setExcelTable(excel)
            }

        }
        setIsLoading(false);
    },[selectedGroup])


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });


    return (
        <div>
            <LykioButton onClick={handlePrint} variant="contained" style={{ color: 'white', marginTop: '10px', marginLeft: '5px' }} >
                <PrintSharpIcon fontSize='small'/>&nbsp;<span style={{textDecoration:'underline'}}>{t('Print this page')}</span>
            </LykioButton>
            <LykioButton variant="contained" style={{ color: 'white', marginTop: '10px', marginLeft: '5px' }} >
                <CSVLink data={excelTable} filename={csvFileName} style={{ color: '#eeeeee', fontSize: 14}}><TextSnippetSharpIcon fontSize='small'/>&nbsp;{t('Export as CSV')}</CSVLink>
            </LykioButton>
            <Container maxWidth={'xl'}>
                {isLoading ? (
                    <Loading />
                ) : (
                    <Box bgcolor={'white'} m={4} p={3} sx={{borderRadius: 2}} ref={componentRef}>
                        <Typography variant="h5" gutterBottom component="div">{t('Reporting')}</Typography>

                        {userRole === ROLE_ADMIN && selectedGroup && (
                            <>
                            <div className="d-flex justify-content-start">
                               <div>
                                   <Autocomplete
                                       sx={{ width: 300, paddingTop: 5 }}
                                       size={'small'}
                                       autoHighlight
                                       options={groups}
                                       getOptionLabel={(option) => option.name ? option.name : ''}
                                       value={selectedGroup}
                                       onChange={(event, val) => {
                                           if(val !== null) {
                                               setSelectedGroup(val)
                                           }
                                       }}
                                       renderInput={(params) => <TextField {...params} label="Select a Class" />}

                                   />
                               </div>

                                {classStats ? ( <Box sx={{ mx: 4 }} style={{height: '25vh', width: '60vw'}}>
                                    <Bar
                                        data={{
                                            labels: ['Total Workbook','Total Game','Grammar', 'Vocabulary', 'Reading', 'Use of English'],
                                            datasets: [
                                        {
                                            label: 'Score',
                                            backgroundColor: [
                                                'rgba(27,71,127,1.00)',
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(150,169,127,1.00)'

                                            ],
                                            // borderColor: 'rgba(0,0,0,1)',
                                            data: [
                                            classStats.classTotalWorkBook,
                                            classStats.classTotalGame,
                                            classStats.classTotalGrammar,
                                            classStats.classTotalVocabulary,
                                            classStats.classTotalReading,
                                            classStats.classTotalUseOfEnglish],
                                            datalabels: {
                                            formatter: function(value, context) {
                                            return value + '%';
                                        }
                                        },
                                        }
                                            ]}}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                title:{
                                                    display:true,
                                                    text:'Class Average Score',
                                                    fontSize:20
                                                },
                                                legend: {
                                                    display: false
                                                }
                                            },



                                        }}/>
                                </Box>) : ''}



                            </div>
                                {/*<div className="d-flex justify-content-end">*/}
                                {/*<LykioButton variant="contained" style={{ color: 'white' }}>*/}
                                {/*    <CSVLink data={excelTable} filename={csvFileName} style={{ color: '#eeeeee', fontSize: 14}}><TextSnippetSharpIcon fontSize='small'/>&nbsp;{t('Export as CSV')}</CSVLink>*/}
                                {/*</LykioButton>*/}
                                {/*</div>*/}

                                {users.length > 0 ? (
                                    <div className="lykiocss-material-table my-4">
                                        {/*<h5>{selectedGroup===':groupReference' ? tenantProperties.name : selectedGroup} {users && `(${users.length} users)`}</h5>*/}
                                        <UsersTable users={users} assignAdminRole={assignAdminRole}/>
                                    </div>
                                ) : (
                                    <div className="">
                                        {t('No entries')}
                                    </div>
                                )}
                            </>
                        )}
                        {userRole === ROLE_TEACHER && selectedGroup && (
                            <>
                                <div className="d-flex justify-content-start">
                                    <Autocomplete
                                        sx={{ width: 300, paddingTop: 5 }}
                                        size={'small'}
                                        autoHighlight
                                        options={groups}
                                        getOptionLabel={(option) => option.name ? option.name : ''}
                                        value={selectedGroup}
                                        onChange={(event, val) => {
                                            if(val !== null) {
                                                setSelectedGroup(val)
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select a Class" />}

                                    />
                                    {classStats ? ( <Box sx={{ mx: "auto" }} style={{height: '25vh', width: '60vw'}}>
                                        <Bar
                                            data={{
                                                labels: ['Total Workbook','Total Game','Grammar', 'Vocabulary', 'Reading', 'Use of English'],
                                                datasets: [
                                                    {
                                                        label: 'Score',
                                                        backgroundColor: [
                                                            'rgba(255, 99, 132, 0.2)',
                                                            'rgba(54, 162, 235, 0.2)',
                                                            'rgba(255, 206, 86, 0.2)',
                                                            'rgba(75, 192, 192, 0.2)',
                                                        ],
                                                        // borderColor: 'rgba(0,0,0,1)',
                                                        data: [
                                                            classStats.classTotalWorkBook,
                                                            classStats.classTotalGame,
                                                            classStats.classTotalGrammar,
                                                            classStats.classTotalVocabulary,
                                                            classStats.classTotalReading,
                                                            classStats.classTotalUseOfEnglish],
                                                        datalabels: {
                                                            formatter: function(value, context) {
                                                                return value + '%';
                                                            }
                                                        },
                                                    }
                                                ]}}
                                            options={{
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    title:{
                                                        display:true,
                                                        text:'Class Average Score',
                                                        fontSize:20
                                                    }
                                                },



                                            }}/>
                                    </Box>) : ''}

                                </div>

                                {users.length > 0 ? (
                                    <div className="lykiocss-material-table my-4">
                                        {/*<h5>{selectedGroup===':groupReference' ? tenantProperties.name : selectedGroup} {users && `(${users.length} users)`}</h5>*/}
                                        <UsersTable users={users} assignAdminRole={assignAdminRole}/>
                                    </div>
                                ) : (
                                    <div className="">
                                        {t('No entries')}
                                    </div>
                                )}
                            </>
                        )}




                    </Box>
                )}
            </Container>
            <SupportEmail/>
        </div>
    )
}

export default TTReporting;