import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal'
import LykioButton from "../common/LykioButton";
import Form from "react-bootstrap/Form";
import {
  AUTHORIZED_URL_PREFIX,
  MASS_ACCESS_CODES_UPLOAD_URL,
  NOTIFICATION_SEVERITY_INFO,
  NOTIFICATION_SEVERITY_SUCCESS,
  NOTIFICATION_SEVERITY_WARNING,
  ACCESS_CODES,
  CREATE_ACCESS_CODE_URL,
  AxiosConfig,
} from "../../Utils/Constants";
import Loading from "../common/Loading";
import t from "../../Utils/Translation";
import { useNavigate } from "react-router";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import friendlyDate from '../../Utils/FriendlyDate';
import TextSnippetSharpIcon from '@mui/icons-material/TextSnippetSharp';

const AddAccessCode = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
  const [serial, setSerial] = useState();
  const [type, setType] = useState("Workbook");
  const [level, setLevel] = useState(1);
  const [price, setPrice] = useState(0);
  const [duration, setDuration] = useState(365);
  const [availableCodes, setAvailableCode] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [types, setTypes] = useState(["Workbook", "TeacherBook", "StudentBook"])
  const [file, setFile] = useState();

  const [selectedLevel, setSelectedLevel] = useState(1);

  useEffect(() => {
    const fetchAccessCodes = async () => {
      try {
        const { data } = await axios.post(
          `${AUTHORIZED_URL_PREFIX}${ACCESS_CODES}`,{selectedLevel:selectedLevel},
          AxiosConfig()
        );
        if (data.success) {
          setAvailableCode(data.accessCodes);
          setIsLoading(false);
        } else {
          notify(pleaseRefreshError);
        }
      } catch (error) {
        console.error(error);
        notify(pleaseRefreshError);
      }
    };

    fetchAccessCodes();
  }, [selectedLevel]);

  const addNewAccessCode = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${CREATE_ACCESS_CODE_URL}`,
        {serial, type, level, price, duration },
        AxiosConfig()
      );
      if (data.success === true) {
        notify({
          title: `Code ${data.accessCode.serial} was successfully generated.`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        });
        setPrice(0);
        setSerial();
        setDuration(365);
        setLevel(1);
		window.location.reload();
      } else {
        notify(pleaseRefreshError);
      }
    } catch (error) {
      console.log(error);
      notify(pleaseRefreshError);
    }
  };

  const massUploadAccessCodes = async()=>{
		if(file){
			try {
			  const formData = new FormData();
			  formData.append('file', file);
			  setIsLoading(true);
			  const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${MASS_ACCESS_CODES_UPLOAD_URL}`,
				formData,
				AxiosConfig('multipart/form-data'),
			  )
			  if(data.success) {
				setFile(null);
				setIsLoading(false);
				handleClose();

				if(data.faultyCodes.length>0){
					let message = 'Some access codes were not added due to inconsistencies:'
					data.faultyCodes.forEach(code => {
						message+=` ${code} `
					})
					notify({
						title: message,
						severity: NOTIFICATION_SEVERITY_WARNING,
						creationDate: '',
						})
				}else{
					notify({
					title: `${t('Your access codes were successfully created. You can find them in the access codes table. If they do not show up yet, reload the page.')}`,
					severity: NOTIFICATION_SEVERITY_INFO,
					creationDate: '',
					})
				}
			  } else {
				handleClose();
				notify(pleaseRefreshError)
			  }
			}catch (error) {
			  console.log(error)
			  notify(pleaseRefreshError)
			}
			}
			else{
			  notify({
				title: `${t('Please upload a file')}`,
				severity: NOTIFICATION_SEVERITY_INFO,
				creationDate: '',
			  })
			}
	}

  return (
    <div className="lykioccs-main-content">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="lykiocss-center lykiocss-margin-top-2em">
          <Container>
            <Row>
              <Col>
                <Form
                  onSubmit={addNewAccessCode}
                  className="lykiocss-form lykiocss-form-border lykiocss-form-size"
                  style={{paddingBottom:'3.5rem'}}
                >

                  <h3 className="lykiocss-center">
                    {t("Mass Upload Access Codes")}
                  </h3>
                  <React.Fragment>
                    <div className="lykiocss-center">
						        <LykioButton className="lykiocss-center" onClick={handleShow}  variant="contained">{t('Import from csv')}</LykioButton>
                    </div>
                      {/*<a className="lykiocss-center mt-2" href="https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/mass_upload_access_codes_template.xlsx"><TextSnippetSharpIcon/>template file</a>*/}
                      <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                  <Modal.Title>{t('Mass Upload Access Codes')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>{t('You can upload upload .xlsx files like the provided sample. If any inconsistencies exist ,you will be notified with an error.')}&nbsp;
                      <a href="https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/mass_upload_access_codes_template.xlsx"><TextSnippetSharpIcon/>template file</a>
                      </Form.Label>
                      <Form.Label>
                      {t('Newly created access codes will show up in the table of this screen.')}
                      </Form.Label>
                      <Form.Control
                      filename={file}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      required
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      />
                    </Form.Group>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                  <LykioButton variant="primary" onClick={()=>{massUploadAccessCodes();}}>
                    {t('Upload')}
                  </LykioButton>
                  </Modal.Footer>
                </Modal>
					      </React.Fragment>

                <br/>
                <h3 className="lykiocss-center">
                  {t("Add a new access code")}
                </h3>


                  <Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="group">
                    <Form.Label>{t("Serial Identifier")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Enter serial")}
                      onChange={(e) => {
                        setSerial(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="group">
                    <Form.Label>{t("Access Code Types (Workbook, Studentbook, Teacherbook)")}</Form.Label>
                    <Form.Select onChange={(e) => {setType(e.target.value)}}>
                      {types.map((availableType) => (
                        <option
                          selected = {type===availableType}
                          value={availableType}
                        >
                          {availableType}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>




                  <Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="group">
                    <Form.Label>{t("Level")}</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder={t("Enter level")}
                      onChange={(e) => {
                        setLevel(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="group">
                    <Form.Label>{t("Price")}</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder={t("Enter price")}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="group">
                    <Form.Label>{t("Duration")}</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder={t("Enter duration in days")}
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <LykioButton variant="contained" className="float-end" type="submit" >
                    {t("Save")}
                  </LykioButton>
                </Form>
              </Col>
              <Col className="lykiocss-margin-top-1em">
                {availableCodes.length<1 ? <div className='lykiocss-title-light lykiocss-center'>{t('You have not created any access codes yet')}</div> :
                <div>
                <h3 className="lykiocss-center">
                    {t("Access Codes")}
                </h3>
                <span>
                    {t("Selected Level")+` (${availableCodes.length} access codes)` }
                </span>
                <Form.Select style={{width:'20%', marginBottom:'0.3em'}} onChange={(e)=> setSelectedLevel(e.target.value)}>
                  {[1,2,3,4,5,6].map((level) => (
                    <option selected = {level===selectedLevel}>{level}</option>
                  ))}
                </Form.Select>
                <div className="table-responsive lykiocss-scrollable-table-400">
                  <Table striped bordered hover  style={{backgroundColor:'white'}}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{t("Access Code")}</th>
                        <th>{t("Assigned To")}</th>
                        <th>{t("Type")}</th>
                        <th>{t("Level")}</th>
                        <th>{t("Duration")}</th>
                        <th>{t("Active")}</th>
                        <th>{t("Activated on")}</th>
                        <th>{t("Price")}</th>
                        <th>{t("Created on")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {availableCodes.map((code, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{code.serial}</td>
                            <td>{code.user ? code.user.email : "nobody"}</td>
                            <td>{code.type}</td>
                            <td>{code.level}</td>
                            <td>{code.duration}</td>
                            <td>{code.active ? "yes" : "no"}</td>
                            <td>{code.activatedDate ? friendlyDate(code.activatedDate) : 'not yet'}</td>
                            <td>{code.price}</td>
                            <td>{code.creationDate ? friendlyDate(code.creationDate) : 'none'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                </div>}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default AddAccessCode;
