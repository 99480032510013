import React, {useState, useEffect, useContext} from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";

import {
    MainContainer,
    ChatContainer,
    MessageList,
    Avatar,
    ConversationHeader,
    Conversation,
    Sidebar,
    ConversationList,
    Message,
    MessageInput,
    MessageSeparator,
    TypingIndicator,
    Search,
    AddUserButton,
    Button, AvatarGroup, SendButton, AttachmentButton, InfoButton,
} from "@chatscope/chat-ui-kit-react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    Autocomplete, Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Paper,
    TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import axios from "axios";
import Loading from './../common/Loading';
import io from 'socket.io-client';
import {format} from 'date-fns'
import {
    AUTHORIZED_URL_PREFIX,
    AxiosConfig, BACKEND_BASE_URL,
    CHAT_MESSAGE_URL,
    CHAT_SEND_MESSAGE_URL, CHAT_UNREAD_URL,
    CHAT_URL, SEARCH_URL, SEARCH_USERS_URL
} from "../../Utils/Constants";
import {toast} from "react-toastify";
import GroupChatDialog from "./GroupChatDialog";
import Messages from "./Messages";
import ConversationMessageInput from "./ConversationMessageInput";
import ConversationMessageHeader from "./ConversationMessageHeader";
import ConversationUserList from "./ConversationUserList";

let socket, selectedChatCompare;

const Chat = () => {
    const context = useContext(LykioContext);
    const tenantProperties = context.tenantProperties;

    const showMessengerFeatures = tenantProperties && tenantProperties.showMessengerFeatures;

    const enableSchools = tenantProperties && tenantProperties.enableSchools

    const loggedUser = JSON.parse(localStorage.getItem('user'));
    const [isLoading, setIsLoading] = useState(true);
    const [selectedChat, setSelectedChat] = useState();
    const [messages, setMessages] = useState([]);
    const [chats, setChats] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [search, setSearch] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);
    const [openGroupDialog, setOpenGroupDialog] = useState(false);
    const [chatUser, setChatUser] = useState('');
    const [socketConnected, setSocketConnected] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);



    const fetchChats = async () =>{

        try {
            const {data} = await axios.get(`${AUTHORIZED_URL_PREFIX}${CHAT_URL}`, AxiosConfig());
            setIsLoading(false);
            setChats(data.results);
        }catch (e) {
            console.log(e)
        }
    };

    const fetchMessages = async () => {
        if (!selectedChat) return;

        try {
            const {data} = await axios.post(`${AUTHORIZED_URL_PREFIX}${CHAT_MESSAGE_URL}`,
                {chatId: selectedChat._id},
                AxiosConfig());
            setMessages(data.messages);

        } catch (e) {
            console.log(e);
        }
    };



    const handleSearch = async () => {
        try {
            setOpenSearch(true);
            const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${SEARCH_USERS_URL}`, AxiosConfig());
            console.log(data.users)
            setSearch(data.users);

            if (!data.success) {
                notify(pleaseRefreshError);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const accessChat = async (userId) => {

        try {
            setOpenSearch(false);
            const {data} = await axios.post(`${AUTHORIZED_URL_PREFIX}${CHAT_URL}`,
                {userId},
                AxiosConfig());
                if (!chats.find((c) => c._id === data.FullChat._id)) setChats([data.FullChat, ...chats]);
                setSelectedChat(data.FullChat);

        }catch (e) {
            toast({
                title: "Error fetching the chat",
                description: e.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-left",
            });
        }
    }




    useEffect(() => {
       fetchChats();
    },[fetchAgain]);

    useEffect(() => {
        fetchMessages();

        selectedChatCompare = selectedChat;

    }, [selectedChat]);

    useEffect(() => {
        //TODO change localhost
            socket = io('http://localhost:3000');
            socket.emit("setup", loggedUser.id);
            socket.on("connected", () => setSocketConnected(true) );

    }, []);

    useEffect(() => {
        socket.on("message recieved", (newMessageRecieved) => {
            if (
                !selectedChatCompare || // if chat is not selected or doesn't match current chat
                selectedChatCompare._id !== newMessageRecieved.chat._id
            ){
                setFetchAgain(!fetchAgain);
            } else {
                setMessages([...messages, newMessageRecieved]);
            }
        });

    });

    const getSender =  (users) => {
        return users[0]._id === loggedUser.id ? users[1] : users[0];
    };

    const handleSelectChat = async (chat) => {
        try {
            const {data} = await axios.post(`${AUTHORIZED_URL_PREFIX}${CHAT_UNREAD_URL}`,
                {chatId: chat._id},
                AxiosConfig());

            setSelectedChat(chat);
        } catch (e) {
            console.log(e);
        }
    }

    const getUnreadDot = (chat) => {
        if (!chat.latestMessage) return false;

        if (chat.latestMessage.sender._id !== loggedUser.id) {
            return !chat.receiverHasRead
        }
        return false;
    }





    return (
    <div className="lykioccs-main-content">
        {isLoading ? (
          <Loading/>
        ) : (
        <MainContainer>
            <Sidebar position="left" scrollable={true}>
                {/*<Search placeholder="Search..." />*/}
                <ConversationHeader>
                    <Avatar
                        src={loggedUser.profilepicture}
                        name= {loggedUser.firstname ? loggedUser.firstname : loggedUser.email}
                    />
                    <ConversationHeader.Content
                        userName= {loggedUser.firstname ? loggedUser.firstname : loggedUser.email}
                        info="Active"
                    />
                </ConversationHeader>
                <ConversationHeader>
                    <ConversationHeader.Content info={chats ? `Chats (${chats.length})` : ''}/>
                </ConversationHeader>
                {chats.length > 0 ?
                    <ConversationList>
                        {chats.map((chat) => {
                                 const sender = getSender(chat.users)
                                return (
                                     <ConversationUserList as={Conversation}
                                        key={chat._id}
                                        chat = {chat}
                                        handleSelectChat = {handleSelectChat}
                                        getUnreadDot = {getUnreadDot}
                                        selectedChat = {selectedChat}
                                        sender = {sender}
                                        showMessengerFeatures = {showMessengerFeatures}
                                     />
                                    )
                        })
                        }
                    </ConversationList> : (
                       <Box
                           display="flex"
                           height='100%'
                           flexDirection='column'
                           justifyContent='center'
                           textAlign='center'
                           fontSize='1rem'
                            >
                           Add users to chat
                       </Box>
                    )

                }

                <AddUserButton border onClick={handleSearch}/>
                {showMessengerFeatures ?
                    (<Button border icon={<AddIcon/>} labelPosition={'left'} onClick={() => setOpenGroupDialog(true)}>New
                        Group Chat</Button>) : ''
                }
                </Sidebar>
            { selectedChat ? (
            <ChatContainer className={enableSchools ? 'enabledSchoolsBackground' : ''}>
                <ConversationMessageHeader as={ConversationHeader}
                    selectedChat = {selectedChat}
                    getSender = {getSender}
                />
                {messages ? (
                    <Messages as={MessageList}
                        isTyping = {isTyping}
                        loggedUser = {loggedUser}
                        messages = {messages}
                    />

                ) : ('')}
                <ConversationMessageInput as={MessageInput}
                    selectedChat = {selectedChat}
                    socket = {socket}
                    messages = {messages}
                    setMessages = {setMessages}
                    showMessengerFeatures ={showMessengerFeatures}

                />
            </ChatContainer>
            ) : (
                <ChatContainer className={enableSchools ? 'enabledSchoolsBackground' : ''}>
                    <MessageList>
                        <MessageList.Content style={{
                            display: "flex",
                            'flexDirection': "column",
                            'justifyContent': "center",
                            height: "100%",
                            textAlign: "center",
                            fontSize: "1rem",
                        }}>
                            Select a user to start a conversation
                        </MessageList.Content>
                    </MessageList>
                </ChatContainer>

            )}
        </MainContainer>
        )}

        <Dialog
            fullWidth
            maxWidth= 'md'
            open={openSearch}
            onClose={() => setOpenSearch(false)}>
            <DialogTitle>Add user to chat</DialogTitle>
            <DialogContent >
                <Box>
                    <Autocomplete
                        sx={{pt:2}}
                        loading
                        id="combo-box"
                        options={search ? search : []}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Select User..." />}
                        onChange={(event, value) => setChatUser(value ? value.item.result._id : '')}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenSearch(false)}>Cancel</Button>
                <Button autoFocus onClick={() => accessChat(chatUser)}>
                    ADD
                </Button>
            </DialogActions>
        </Dialog>

        <GroupChatDialog
            openGroupDialog={openGroupDialog}
            setOpenGroupDialog={setOpenGroupDialog}
            setChats = {setChats}
            chats = {chats}
            setSelectedChat = {setSelectedChat}
        />
    </div>

    );
}

export default Chat;