import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import t from "../../Utils/Translation";
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import { AUTHORIZED_URL_PREFIX, LIBRARY_URL_WITHOUT_PARAM, AxiosConfig, 
	LIBRARY_UNITS, LIBRARY_PROGRAMS, LIBRARY_RESOURCES,
	LIBRARY_WORKBOOK, LIBRARY_STUDENTBOOK, LIBRARY_TEACHERBOOK,
	LIBRARY_AUDIO, LIBRARY_VIDEO, LIBRARY_FLASHCARDS, LIBRARY_EXTRA_MATERIAL, NOTIFICATION_SEVERITY_INFO } from '../../Utils/Constants';
import './library.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Units from './../home/Units';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SubCategoryModal from './SubcategoryModal';
import Ratio from 'react-bootstrap/Ratio';
import Tooltip from '@mui/material/Tooltip';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import android_d from './../../multimedia/android_d.png';
import osx_d from './../../multimedia/osx_d.png';
import ios_d from './../../multimedia/ios_d.png';
import win_d from './../../multimedia/win_d.png';
import SupportEmail from '../common/SupportEmail';
const teacherVersionSuffix = '(Teacher Version)';

const Library = () => {
	const context = useContext(LykioContext);
	const tenantProperties = context.tenantProperties;
	const makeHeadersDark = tenantProperties && tenantProperties.makeHeadersDark;
	const enableSchools = tenantProperties && tenantProperties.enableSchools;
	const [ rootCategoriesName, setRootCategoriesName ] = useState(tenantProperties && tenantProperties.rootCategoriesName);
	const [activeAccessCodes, setActiveAccessCodes] = useState([]);
	const [accessibleLevels, setAccessibleLevels] = useState([]);
	const [accessibleBooks, setAccessibleBooks] = useState([])
	const [ isLoading, setIsLoading ] = useState(true);
	const [ units, setUnits ] = useState([]);
	const [ subCategories, setSubCategories ] = useState([]);
	const [ allCategories, setAllCategories] = useState([]);
	const [ stats, setStats ] = useState();
	const { categoryreference, libraryType } = useParams();
	console.log('category reference', categoryreference);
	console.log('library type', libraryType);
	const [categoryDescription, setCategoryDescription] = useState('');
	const language = localStorage.getItem('content-i18n');

	useEffect(
		() => {
			const fetchLibraryData = async () => {
				try {
					const { data } = await axios.post(
						`${AUTHORIZED_URL_PREFIX}${LIBRARY_URL_WITHOUT_PARAM}`,
						{ categoryreference, language, enableSchools },
						AxiosConfig()
					);
					if(data.success) {
					  setActiveAccessCodes(data.activeAccessCodes);
					  let accessLevels = [];
					  let accessBooks = [];
					  for (let i = 0; i < data.activeAccessCodes.length; i++){
						accessLevels.push(`Level${data.activeAccessCodes[i].level}`.toLowerCase());
						accessBooks.push(`Level${data.activeAccessCodes[i].level}-${data.activeAccessCodes[i].type}`.toLowerCase())
					  }
					  setAccessibleLevels(accessLevels);
					  setAccessibleBooks(accessBooks);
					  setUnits(data.units);
					  setStats(data.stats);
					  setAllCategories(data.allCategories);
					  setSubCategories(data.subCategories);
					  setCategoryDescription(data.categoryDescription);
					  setIsLoading(false);
					}else{
					  notify(pleaseRefreshError);
					}
				} catch (error) {
					console.error(error);
					notify(pleaseRefreshError);
				}
			};
			fetchLibraryData();
		},
		[ categoryreference, libraryType ]
	);

	console.log(libraryType);
	return libraryType===LIBRARY_UNITS ?
	<StandardLibrary 
		isLoading={isLoading} 
		makeHeadersDark={makeHeadersDark}
		allCategories={allCategories}
		categoryreference={categoryreference}
		categoryDescription={categoryDescription}
		subCategories={subCategories}
		units={units}
		rootCategoriesName={rootCategoriesName}
		libraryType={libraryType}
	/> : 
	<EducationLibrary 
		isLoading={isLoading} 
		makeHeadersDark={makeHeadersDark}
		allCategories={allCategories}
		categoryreference={categoryreference}
		categoryDescription={categoryDescription}
		subCategories={subCategories}
		units={units}
		rootCategoriesName={rootCategoriesName}
		libraryType={libraryType}
		workbookActivated={accessibleBooks.includes(`${categoryreference}-${LIBRARY_WORKBOOK}`.toLowerCase())}
		studentbookActivated={accessibleBooks.includes(`${categoryreference}-${LIBRARY_STUDENTBOOK}`.toLowerCase())}
		teacherbookActivated={accessibleBooks.includes(`${categoryreference}-${LIBRARY_TEACHERBOOK}`.toLowerCase())}
		levelIsActivated={accessibleLevels.includes(categoryreference.toLowerCase())}
		tenantProperties = {tenantProperties}
		enableSchools = {enableSchools}
	/>;
};

export default Library;

const EducationLibrary = (props)=>{
	const isLoading = props.isLoading;
	const makeHeadersDark = props.makeHeadersDark;
	const allCategories = props.allCategories;
	const subCategories = props.subCategories;
	const units = props.units;
	const tenantProperties = props.tenantProperties;
	const enableSchools = props.enableSchools;
	console.log('units>',units);
	const categoryreference = props.categoryreference;
	const libraryType = props.libraryType;
	let workbookActivated = props.workbookActivated;
	if(localStorage.getItem("workbookActivated")==='true'){
		workbookActivated = true;
	}
	else{
		localStorage.setItem('workbookActivated',props.workbookActivated);
	}

	let studentbookActivated = props.studentbookActivated;
	if(localStorage.getItem("studentbookActivated")==='true'){
		studentbookActivated = true;
	}
	else{
		localStorage.setItem('studentbookActivated',props.studentbookActivated);
	}

	let teacherbookActivated = props.teacherbookActivated;
	if(localStorage.getItem("teacherbookActivated")==='true'){
		teacherbookActivated = true;
	}
	else{
		localStorage.setItem('teacherbookActivated',props.teacherbookActivated);
	}

	let levelIsActivated = props.levelIsActivated;
	if(localStorage.getItem("levelIsActivated")==='true'){
		levelIsActivated = true;
	}
	else{
		localStorage.setItem('levelIsActivated',props.levelIsActivated);
	}

	// the units contain entries only for the selected level
	let programUnits = [];
	let finalOrderUnits = [];

	let filteredSubCategories = [];
	if(libraryType===LIBRARY_RESOURCES){
		subCategories.forEach(subCategory => {
			if(levelIsActivated && (subCategory.reference.toLowerCase()===`${categoryreference}-${LIBRARY_AUDIO}`.toLowerCase() || 
			subCategory.reference.toLowerCase()===`${categoryreference}-${LIBRARY_VIDEO}`.toLowerCase() || 
			subCategory.reference.toLowerCase()===`${categoryreference}-${LIBRARY_FLASHCARDS}`.toLowerCase() || 
			subCategory.reference.toLowerCase()===`${categoryreference}-${LIBRARY_EXTRA_MATERIAL}`.toLowerCase()
			)
			){
				filteredSubCategories.push(subCategory);
			}
		})
	}
	else if(libraryType===LIBRARY_PROGRAMS){
		subCategories.forEach(subCategory => {
			if(studentbookActivated && subCategory.reference.toLowerCase()===`${categoryreference}-${LIBRARY_STUDENTBOOK}`.toLowerCase()){
				filteredSubCategories.push(subCategory);
			}
			if(workbookActivated && subCategory.reference.toLowerCase()===`${categoryreference}-${LIBRARY_WORKBOOK}`.toLowerCase()){
				filteredSubCategories.push(subCategory);
			}
			if(teacherbookActivated && subCategory.reference.toLowerCase()===`${categoryreference}-${LIBRARY_TEACHERBOOK}`.toLowerCase()){
				filteredSubCategories.push(subCategory);
			}
		})

		const userRole = localStorage.getItem('userRole');

		units.forEach(unit => {
			if(studentbookActivated && unit.categoryreference.toLowerCase().includes(LIBRARY_STUDENTBOOK.toLowerCase())
			){
				console.log('adding studentbook')
				programUnits.push(unit);
			}
			if((workbookActivated || userRole==='admin' || userRole==='teacher') && unit.categoryreference.toLowerCase().includes(LIBRARY_WORKBOOK.toLowerCase())
			){
				if((teacherbookActivated || userRole==='admin' || userRole==='teacher') && unit.title.toLowerCase().includes(teacherVersionSuffix.toLowerCase())){
					console.log('adding teacher version of workbook')
					programUnits.push(unit);
				}
				if(!teacherbookActivated && !unit.title.toLowerCase().includes(teacherVersionSuffix.toLowerCase())){
					console.log('adding student version of workbook')
					programUnits.push(unit);
				}
			}
			if((teacherbookActivated || userRole==='admin' || userRole==='teacher') && unit.categoryreference.toLowerCase().includes(LIBRARY_TEACHERBOOK.toLowerCase())
			){
				console.log('adding teacherbook')
				programUnits.push(unit);
			}
		})

		if(programUnits.length===3) {
		   finalOrderUnits.push(programUnits[2]);
		   finalOrderUnits.push(programUnits[0]);
		   finalOrderUnits.push(programUnits[1]);
		   programUnits = finalOrderUnits;
		} else {
			if(programUnits.length===2) {
				if (studentbookActivated) {
					finalOrderUnits.push(programUnits[1]);
					finalOrderUnits.push(programUnits[0]);
					programUnits = finalOrderUnits;	
				} 
			}
		}

	}

	return <div className="lykioccs-main-content lykiocss-category-bg lykioccs-category-library">
	{isLoading ? <Loading /> :<React.Fragment>
		<Container style={{marginTop:'1em', marginLeft:'1em !important'}}>

		<Row>
			<Col>
				<h1 className={`lykiocss-page-title ${makeHeadersDark && 'lykiocss-dark-color'}`} style={{marginTop:'0em'}}>
					{libraryType}
				</h1>
			</Col>
		</Row>
	</Container>
	
	{libraryType===LIBRARY_RESOURCES && 
	<div style={{marginLeft:'2em'}}>
		<h1 className={`lykiocss-slider-title ${makeHeadersDark && 'lykiocss-dark-color'}`} >Game Downloads</h1>
		<DownloadSplider tenantProperties={tenantProperties} teacherbookActivated={teacherbookActivated}/>
	</div>
	}

	{libraryType===LIBRARY_RESOURCES && 
	<div style={{marginLeft:'2em'}}>
		{filteredSubCategories.map(subCategory => {
			if(!teacherbookActivated && subCategory.reference.includes('ExtraMaterial')){
				return <></>
			}else{
				return <Units libraryType={LIBRARY_RESOURCES} 
				makeHeadersDark={makeHeadersDark} 
				units={units.filter(unit => unit.categoryreference === subCategory.reference)}
				title={
					subCategory.reference.includes('Video') ? 'Videos' :
					subCategory.reference.includes('Flashcards') ? 'Flashcards' :
					subCategory.reference.includes('Audio') ? 'Audio' :
					subCategory.reference.includes('ExtraMaterial') ? 'Extra Material' : ''
				}
				teacherbookActivated={teacherbookActivated}
				studentbookActivated={studentbookActivated}
				workbookActivated={workbookActivated}
				/>
			}
		})
		}
	</div>
	}

	{libraryType===LIBRARY_PROGRAMS && programUnits.length>0 &&
	<div style={{marginLeft:'2em'}}>
		<Units libraryType={LIBRARY_PROGRAMS} makeHeadersDark={makeHeadersDark} units={programUnits} title={''}/>
	</div>
	}
	{enableSchools && <SupportEmail/>}
	</React.Fragment>
	}
</div>
}


const StandardLibrary = (props)=>{
	const isLoading = props.isLoading;
	const makeHeadersDark = props.makeHeadersDark;
	const allCategories = props.allCategories;
	const categoryreference = props.categoryreference;
	const categoryDescription = props.categoryDescription;
	const subCategories = props.subCategories;
	const units = props.units;
	const rootCategoriesName = props.rootCategoriesName;
	const libraryType = props.libraryType;

	return <div className="lykioccs-main-content lykiocss-category-bg lykioccs-category-library">
	{isLoading ? <Loading /> :<React.Fragment><Container style={{marginTop:'1em', marginLeft:'1em !important'}}>      
			<Row>
				<Col xs={12}>
					<h1 className={`lykiocss-page-title ${makeHeadersDark && 'lykiocss-dark-color'}`} style={{marginTop:'0em'}}>{(allCategories.filter(category=> category.reference===categoryreference)[0]) && allCategories.filter(category=> category.reference===categoryreference)[0].name}</h1>
					{categoryDescription && categoryDescription.length>1 && 
					<div style={{fontSize:'x-large'}} className="lykiocss-library-category-header">
						<p>{categoryDescription}</p>
					</div>
					}
				</Col>
				<Col xs={12}>
					{subCategories.map(subCategory => {
						return <SubCategoryModal subCategory={subCategory} allCategories={allCategories}/>
					})
					}
				</Col>
			</Row>
	</Container>
	<div style={{marginLeft:'2em'}}>
		{subCategories.map(subCategory => {
			return <Units libraryType={libraryType} makeHeadersDark={makeHeadersDark} units={units.filter(unit => unit.categoryreference === subCategory.reference)} title={(allCategories.filter(category=> category.reference===subCategory.reference)[0]) && allCategories.filter(category=> category.reference===subCategory.reference)[0].name}/>
		})
		}
	</div>

	<div style={{marginLeft:'2em'}}>
		{units.filter(unit => unit.categoryreference === categoryreference).length>0 && 
			<Units libraryType={libraryType} makeHeadersDark={makeHeadersDark} units={units.filter(unit => unit.categoryreference === categoryreference)} title={rootCategoriesName ? rootCategoriesName : (allCategories.filter(category=> category.reference===categoryreference)[0]) && allCategories.filter(category=> category.reference===categoryreference)[0].name} />
		}
	</div>
	</React.Fragment>
	}
</div>
}


function DownloadSplider(props) {
	const downloads = [
		{operatingSystem: 'Windows', link: props.tenantProperties.windowsDownloadLink, cover: win_d},
		{operatingSystem: 'OSX', link: props.tenantProperties.macDownloadLink, cover: osx_d},
		{operatingSystem: 'Android', link: props.tenantProperties.androidDownloadLink, cover: android_d},
		// {operatingSystem: 'iOS', link: props.tenantProperties.iosDownloadLink, cover: ios_d}
	];
	
	const options = {
	  type: 'slide',
	  width: '80%',
	  perPage: 5,
	  perMove: 3,
	  breakpoints: {
		990: {
		  perPage: 3,
		  perMove: 2
		},
		767: {
		  perPage: 2,
		  perMove: 1
		},
		640: {
		  perPage: 1,
		  perMove: 1
		}
	  },
	  rewind: true,
	  gap: '0.1em',
	  ...props
	}
	  return ( <Splide
		  options={ options }>
			{downloads.map((download, i) => {
			  return (
				<SplideSlide key={i}>
				  <GameDownloadTile download={download} index={i} teacherbookActivated={props.teacherbookActivated}/>
				</SplideSlide>
			  );
			})}
		  </Splide> );
  }
  
  const GameDownloadTile = (props)=>{

	let link = props.download.link;
	const teacherbookActivated = props.teacherbookActivated;

	if(teacherbookActivated && props.download.operatingSystem==='iOS'){
		link='demo_url'
	}
	if(teacherbookActivated && props.download.operatingSystem==='OSX'){
		link='https://lykio-dev-data.s3.eu-central-1.amazonaws.com/FTP/games/edilingua/demoTT/tt-client-demo.zip'
	}
	if(teacherbookActivated && props.download.operatingSystem==='Android'){
		link='https://lykio-dev-data.s3.eu-central-1.amazonaws.com/FTP/games/edilingua/demoTT/tt-client-demo.apk'
	}
	if(teacherbookActivated && props.download.operatingSystem==='Windows'){
		link='https://lykio-dev-data.s3.eu-central-1.amazonaws.com/FTP/games/edilingua/demoTT/tt-client-demo.zip'
	}

	return <React.Fragment key={props.index}>
	<div onClick={() => {
			if(props.download.operatingSystem==='iOS'){
				window.open(link, '_blank', 'popup');
			}else{
				notify({
					title: `${t('Click on the icon in the center to download the file')}`,
					severity: NOTIFICATION_SEVERITY_INFO,
					creationDate: '',
				  });
			}
	  }
	  } className="lykiocss-story-item">

		<Ratio aspectRatio="1x1">
		<img
		  src={props.download.cover}
		  className="lykiocss-story-image"
		  alt="story-cover"
		/>
		</Ratio>

	  <div className="lykiocss-story-item-type" />
	  <div className="lykiocss-story-item-visible-info">
		<Container className='lykiocss-top-overlay'>
		  <Row>
			<div className='lykiocss-story-item-title'>
			   {props.download.operatingSystem}
			</div>
		  </Row>
		</Container>
	  </div>
	  <div className="lykiocss-story-item-info">
	  <Container>
	  <div>
		<Row>
		<div className='lykiocss-center lykiocss-story-description' style={{fontSize:'4.5em'}}>
		<Tooltip title={props.download.operatingSystem}>
		<a href={link} ><DownloadSharpIcon fontSize="inherit"/></a>
		</Tooltip>
		</div>
		</Row>
	  </div>
	</Container>
	
	  </div>
	</div>
	</React.Fragment>
}