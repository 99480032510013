import React, { useState, useEffect, useContext } from 'react';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import axios from 'axios';
import t from "../../Utils/Translation";
import { AUTHORIZED_URL_PREFIX, SETTINGS_URL, UPDATE_SETTINGS_URL,NOTIFICATION_SEVERITY_SUCCESS,NOTIFICATION_SEVERITY_WARNING,LANGUAGES_ARRAY, TIMEZONES,AVAILABLE_THEMES, AxiosConfig } from '../../Utils/Constants';
import './settings.css';
// eslint-disable-next-line
import changeLanguage from '../../locales/translations/TranslationUtils';
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";


const Settings = () => {
	const context = useContext(LykioContext);
	const userPermissions = context.userPermissions;
	const tenantProperties = context.tenantProperties;
	const [ isLoading, setIsLoading ] = useState(true);
	const languages = (tenantProperties && tenantProperties.languages && tenantProperties.languages.length>0) ? tenantProperties.languages : LANGUAGES_ARRAY;
	const canPickTheme = tenantProperties && tenantProperties.enableUsersCanPickTheme;
	const [ systemLanguage, setSystemLanguage ] = useState(languages[0].code);
	const [ contentLanguage, setContentLanguage ] = useState(languages[0].code);
	const [ emailPreferences, setEmailPreferences ] = useState('NONE'); // basic all
	const [ showFeatured, setShowFeatured ] = useState(true);
	const [ showHomeStats, setHomeStats ] = useState(true);
	const [ showProgress, setShowProgress ] = useState(true);
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [timezone, setTimezone] = useState();
	console.log('t',(tenantProperties && tenantProperties.themeName) ? tenantProperties.themeName : "Default");
	const [themeName, setThemeName] = useState((tenantProperties && tenantProperties.themeName) ? tenantProperties.themeName : "Default");
	const [enableSchools, setEnableSchools] = useState(true);
	const handleThemeChange = (e) => {
	setThemeName(e.target.value);
	let selectedTheme = e.target.value;
	let themesResults = [];
	if (selectedTheme === "Default") {
		themesResults = AVAILABLE_THEMES.filter(theme => {
			return theme.name === "Default"
		})
	}
	if (selectedTheme === "PearlRiver") {
		themesResults = AVAILABLE_THEMES.filter(theme => {
			return theme.name === "PearlRiver"
		})
	}
	if (selectedTheme === "ViceroyGold") {
		themesResults = AVAILABLE_THEMES.filter(theme => {
			return theme.name === "ViceroyGold"
		})
	}
	if (selectedTheme === "ViceroyIcon") {
		themesResults = AVAILABLE_THEMES.filter(theme => {
			return theme.name === "ViceroyIcon"
		})
	}
	if (selectedTheme === "GhostWhite") {
		themesResults = AVAILABLE_THEMES.filter(theme => {
			return theme.name === "GhostWhite"
		})
	}
	if(themesResults.length>0){
		setThemeName(themesResults[0].name);
	}
	};

	useEffect(() => {
		const fetchSettingsData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${SETTINGS_URL}`, AxiosConfig());
				data.settings.systemLanguage && setSystemLanguage(data.settings.systemLanguage);
				data.settings.contentLanguage && setContentLanguage(data.settings.contentLanguage);
				data.settings.emailPreferences && setEmailPreferences(data.settings.emailPreferences);
				data.settings.timezone && setTimezone(data.settings.timezone);
				data.settings.dashboard && setShowFeatured(data.settings.dashboard.showFeatured);
				data.settings.dashboard && setHomeStats(data.settings.dashboard.showHomeStats);
				data.settings.dashboard && setShowProgress(data.settings.dashboard.showProgress);
				setEnableSchools(data.enableSchools);
				//console.log(tenantProperties.themeName);
				(data.settings.theme && data.settings.theme.themeName) ? setThemeName(data.settings.theme.themeName): setThemeName((tenantProperties && tenantProperties.themeName) ? tenantProperties.themeName : "Default");
				setIsLoading(false);
				if (!data.success){
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.error(error);
				notify(pleaseRefreshError);
			}
		};

		fetchSettingsData();
	}, []);

	const changeContentLanguage=(language)=>{
		localStorage.setItem('content-i18n', language);
	}

	const updateSettingsHandler = async () => {
		let changepass = false;
		try {
			if(password!=='' && password.length<6){
				notify({
					title: 'Password must at least 6 characters long! No change was made to your password.',
					severity: NOTIFICATION_SEVERITY_WARNING,
					creationDate: ''
				});
			}else if (password!=='' && password!==confirmPassword) {
				setPassword('');
				setConfirmPassword('');
				notify({
					title: `${t("Passwords don't match. No change was made to your password.")}`,
					severity: NOTIFICATION_SEVERITY_WARNING,
					creationDate: ''
				});
			}else if(password!=='' && password===confirmPassword){
				changepass = true;
			}
			
			let updatedPassword = changepass ? password : '';
			let updatedTheme = canPickTheme ? themeName : ''
			const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${UPDATE_SETTINGS_URL}`,
        {
          systemLanguage,
          contentLanguage,
          emailPreferences,
          updatedPassword,
          showFeatured,
          showHomeStats,
          showProgress,
          timezone,
		  themeName : updatedTheme
        },
        AxiosConfig()
      );
			if (data.success === true) {
				notify({
					title: `${t('Your settings were successfully updated.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
			}else{
				notify(pleaseRefreshError);
			}
		}catch(error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return <div className="lykioccs-main-content">
		{isLoading ? <Loading /> : <div className="lykiocss-center lykiocss-margin-top-2em">
		<Form className="lykiocss-form lykiocss-form-border lykiocss-form-size">
		<div className="lykiocss-center lykiocss-title-light">{t('Settings')}</div>
		<Container className="lykiocss-margin-top-1em">
			{!enableSchools ? (
				<Row>
					<Col xs={12} md={6}>
						<Form.Group className="mb-3" controlId="systemLanguage" >
							<FloatingLabel controlId="floatingsl" label={t('Change System Language')}>
								<Form.Select onChange={(e) => { setSystemLanguage(e.target.value); changeLanguage(e.target.value) }}>
									{languages.map((language) => (
										<option
											selected = {systemLanguage===language.code}
											value={language.code}
										>
											{t(language.name)}
										</option>
									))}
								</Form.Select>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3" controlId="contentLanguage" >
							<FloatingLabel controlId="floatingcl" label={t('Change Content Language')}>
								<Form.Select onChange={(e) => { setContentLanguage(e.target.value); changeContentLanguage(e.target.value); }}>
									{languages.map((language) => (
										<option
											selected = {contentLanguage===language.code}
											value={language.code}
										>
											{t(language.name)}
										</option>
									))}
								</Form.Select>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3" controlId="contentLanguage" >
							<FloatingLabel controlId="floatingtz" label={t('Change Timezone')}>
								<Form.Select onChange={(e) => { setTimezone(e.target.value); }}>
									{TIMEZONES.map((tz) => (
										<option
											selected = {timezone===tz.name}
											value={tz.name}
										>
											{`${tz.description} ${tz.relativeToGMT}`}
										</option>
									))}
								</Form.Select>
							</FloatingLabel>
						</Form.Group>

						{/*<Form.Group className="mb-3" controlId="emailPreferences"
									onChange={(e) => { setEmailPreferences(e.target.value);}}
						>
							<Form.Label>{t('Change Email Preferences')}</Form.Label><br/>
							<Form.Check
								inline
								label={t('All')}
								checked={emailPreferences==='ALL'}
								value="ALL"
								name="emailPreferences"
								type="radio"
								id={`email-radio-1`}
							/>
							<Form.Check
								inline
								label={t('Basic')}
								checked={emailPreferences==='BASIC'}
								value="BASIC"
								name="emailPreferences"
								type="radio"
								id={`email-radio-2`}
							/>
							<Form.Check
								inline
								label={t('None')}
								checked={emailPreferences==='NONE'}
								value="NONE"
								name="emailPreferences"
								type="radio"
								id={`email-radio-3`}
							/>
						</Form.Group>*/}
					</Col>
					<Col  xs={12} md={6}>
						<Form.Group className="mb-3" controlId="password">
							<FloatingLabel controlId="floatingcp" label={t(`New Password (6 characters or more)`)}>
								<Form.Control type="password" placeholder={t(`Enter password (6 characters or more)`)}
											  value={password}
											  onChange={(e) => {
												  setPassword(e.target.value);
											  }}/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3" controlId="confirmpassword">
							<FloatingLabel controlId="floatingcp" label={t(`Confirm Password`)}>
								<Form.Control type="password" placeholder={t(`Confirm your password`)}
											  value={confirmPassword}
											  onChange={(e) => {
												  setConfirmPassword(e.target.value);
											  }}/>
							</FloatingLabel>
						</Form.Group>
						{canPickTheme &&
							<React.Fragment>
								<Form.Group className="mb-3" controlId="group">
									<FloatingLabel controlId="floatingtheme" label={t(`My Theme`)}>
										<Form.Select onChange={handleThemeChange}>
											{AVAILABLE_THEMES.map((theme, i) => (
												<option
													selected={theme.name === themeName}
													value={theme.name}
												>
													{theme.name}
												</option>
											))}
										</Form.Select>
									</FloatingLabel>
								</Form.Group>
							</React.Fragment>
						}
						<Form.Label>{t("Customize Home Screen")}</Form.Label>
						<Form.Group >
							<Form.Check
								onChange={() => { setShowFeatured(!showFeatured);}}
								checked={showFeatured}
								type="switch"
								id="showFeatured"
								label={t("Show Featured Stories")}
							/>
						</Form.Group>
						<Form.Group >
							<Form.Check
								onChange={() => { setHomeStats(!showHomeStats);}}
								checked={showHomeStats}
								type="switch"
								id="showHomeStats"
								label={t("Show Platform Statistics")}
							/>
						</Form.Group>
						<Form.Group >
							<Form.Check
								onChange={() => { setShowProgress(!showProgress);}}
								checked={showProgress}
								type="switch"
								id="showProgress"
								label={t("Show Your Progress")}
							/>
						</Form.Group>
					</Col>
				</Row>
				) : (
				<Row>
					<Col>
						{/*<Form.Group className="mb-3" controlId="emailPreferences"
									onChange={(e) => { setEmailPreferences(e.target.value);}}
						>
							<Form.Label>{t('Change Email Preferences')}</Form.Label><br/>
							<Form.Check
								inline
								label={t('All')}
								checked={emailPreferences==='ALL'}
								value="ALL"
								name="emailPreferences"
								type="radio"
								id={`email-radio-1`}
							/>
							<Form.Check
								inline
								label={t('Basic')}
								checked={emailPreferences==='BASIC'}
								value="BASIC"
								name="emailPreferences"
								type="radio"
								id={`email-radio-2`}
							/>
							<Form.Check
								inline
								label={t('None')}
								checked={emailPreferences==='NONE'}
								value="NONE"
								name="emailPreferences"
								type="radio"
								id={`email-radio-3`}
							/>
						</Form.Group>*/}
						<Form.Group className="mb-3" controlId="password">
							<FloatingLabel controlId="floatingcp" label={t(`New Password (6 characters or more)`)}>
								<Form.Control type="password" placeholder={t(`Enter password (6 characters or more)`)}
											  value={password}
											  onChange={(e) => {
												  setPassword(e.target.value);
											  }}/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3" controlId="confirmpassword">
							<FloatingLabel controlId="floatingcp" label={t(`Confirm Password`)}>
								<Form.Control type="password" placeholder={t(`Confirm your password`)}
											  value={confirmPassword}
											  onChange={(e) => {
												  setConfirmPassword(e.target.value);
											  }}/>
							</FloatingLabel>
						</Form.Group>
					</Col>

				</Row>
			)}

		</Container>
		<br/>
		<LykioButton onClick={() => updateSettingsHandler()}type="submit" variant="contained" className="float-end">
			{t('Update Settings')}
		</LykioButton>
		</Form>
		</div>}
	</div>
};

export default Settings;
