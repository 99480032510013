import React, { useMemo, useState } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {
    AUTHORIZED_URL_PREFIX,
    SEND_ACTIVATION_URL,
    MODERATE_USER,
    NOTIFICATION_SEVERITY_SUCCESS,
    AxiosConfig,
    PENDING_USER,
    ACTIVE_USER,
    LOCKED_USER,
    USER_EDIT_WITHOUTPARAM_URL, EDUCATIONAL_USER_EDIT_WITHOUTPARAM_URL, ROLE_TEACHER
} from '../../Utils/Constants';
import t from '../../Utils/Translation';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import LykioButton from "../common/LykioButton";
import './usersTable.css';
import friendlyDate from '../../Utils/FriendlyDate';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Form from "react-bootstrap/Form";
import TableContainer from "@mui/material/TableContainer";


const UsersTable = (props) => {
    const navigate = useNavigate();
    const assignAdminRole = props.assignAdminRole;
    const users = props && props.users ? props.users : [];
    const [ myTableData, setmyTableData ] = useState(useMemo(() => [ ...users ], []));
    const loggedUser = JSON.parse(localStorage.getItem('user'));

    //Table Design Headers/Columns
    const myTableColumns = useMemo(
        () => [
            {
                Header: t('Full Name'),
                accessor: 'fullName'
            },
            {
                Header: t('Email'),
                accessor: 'email'
            },
            {
                Header: t('Workbook Attempts'),
                accessor: 'wb_attempts',
            },
            {
                Header: t('Workbook Average Score'),
                accessor: 'wb_averageScore',
                Cell: ({ value }) => {
                    return t(value)+'%';
                }
            },
            {
                Header: t('Game Progress'),
                accessor: 'g_progress',
                Cell: ({ value }) => {
                    return t(value)+'%';
                }
            },
            {
                Header: t('Game TotalScore'),
                accessor: 'g_totalScore',
                Cell: ({ value }) => {
                    return t(value)+'%';
                }
            },
            {
                Header: t('Game Vocabulary'),
                accessor: 'g_vocabulary',
                Cell: ({ value }) => {
                    return t(value)+'%';
                }
            },
            {
                Header: t('Game Grammar'),
                accessor: 'g_grammar',
                Cell: ({ value }) => {
                    return t(value)+'%';
                }
            },
            {
                Header: t('Game Reading'),
                accessor: 'g_reading',
                Cell: ({ value }) => {
                    return t(value)+'%';
                }
            },
            {
                Header: t('Game Use Of English'),
                accessor: 'g_useofenglish',
                Cell: ({ value }) => {
                    return t(value)+'%';
                }
            }
        ],
        []
    );


    //React Table Setup3
    const tableInstance = useTable(
        { columns: myTableColumns, data: myTableData },
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        prepareRow
    } = tableInstance;

    const { pageIndex, pageSize } = state;



    return (
        <React.Fragment>
            <TableContainer>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {column.isSorted ? column.isSortedDesc ? ' ↓' : ' ↑' : ''}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>


            {/*Pagination*/}
            <div className="lykiocss-pagination lykiocss-stick-to-bottom">
                <LykioButton variant="contained" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {t('First')}
                </LykioButton>&nbsp;
                <LykioButton variant="contained" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {t('Previous')}
                </LykioButton>&nbsp;
                <span className="lykiocss-pageNum">
					{' '}
                    {t('Page')} {pageIndex + 1} / {pageOptions.length}{' '}
				</span>&nbsp;
                <span>
					{' '}
                    | {t('Page')}{' '}
                    <input
                        className="lykiocss-pageSelector"
                        type="number"
                        value={pageIndex + 1}
                        onChange={(e) => gotoPage(e.target.value ? Number(e.target.value) - 1 : 0)}
                    />{' '}
				</span>&nbsp;
                <LykioButton variant="contained" onClick={() => nextPage()} disabled={!canNextPage}>
                    {t('Next')}
                </LykioButton>&nbsp;
                <LykioButton
                    variant="contained"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                >
                    {t('Last')}
                </LykioButton>&nbsp;
                <select
                    className="lykiocss-items-count-selector"
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                >
                    {[ 5, 10, 25, 50, 100 ].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {' '}
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </React.Fragment>
    );
};

export default UsersTable;
