import React from 'react';
import { Navigate } from 'react-router-dom'
import { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router'
import {
  FRONTEND_LOGIN_URL,
  AUTHORIZED_URL_PREFIX,
  GET_CONTEXT,
  AVAILABLE_THEMES,
  AxiosConfig,
} from '../../Utils/Constants'
import MetaNavigation from '../navigation/MetaNavigation'
export const LykioContext = createContext('Unknown')

const LykioAuthorizedRoute = ({ children }) => {
  const navigate = useNavigate()
  const [userPermissions, setUserPermissions] = useState([])
  const [tenantProperties, settenantProperties] = useState()
  const [categories, setCategories] = useState()
  const [group, setGroup] = useState()
  const [userDetails, setUserDetails] = useState()

  const homeScreenBackground = (tenantProperties && tenantProperties.homeScreenBackground) && tenantProperties.homeScreenBackground;


  const changeRootThemeValues = (propertyName, propertyValue) => {
    propertyValue && document.documentElement.style.setProperty(propertyName,propertyValue);
  }

  function applyTheme(themeName, showBackgroundImages, backgroundImage) {

    if (!showBackgroundImages) {
      changeRootThemeValues('--background-home-url', `url("")`);
      changeRootThemeValues('--background-stories-url', `url("")`);
      changeRootThemeValues('--background-generic-url', `url("")`);
      changeRootThemeValues('--background-upload-url', `url("")`);
    }
    if (themeName && showBackgroundImages) {
      changeRootThemeValues('--background-home-url', `url(${backgroundImage})`);
      changeRootThemeValues('--background-category-url', `url(${backgroundImage})`);
      changeRootThemeValues('--background-stories-url', `url(${backgroundImage})`);
      changeRootThemeValues('--background-generic-url', `url(${backgroundImage})`);
      changeRootThemeValues('--background-upload-url', `url(${backgroundImage})`);
    }
    
    let selectedTheme = AVAILABLE_THEMES.filter(theme => theme.name === themeName).length > 0 && AVAILABLE_THEMES.filter(theme => theme.name === themeName)[0];
    if (selectedTheme) {
      changeRootThemeValues('--primary-color', selectedTheme.properties.primaryColor);
      changeRootThemeValues('--primary-color-accent', selectedTheme.properties.primaryColorAccent);
      changeRootThemeValues('--secondary-color', selectedTheme.properties.secondaryColor);
      changeRootThemeValues('--secondary-color-accent', selectedTheme.properties.secondaryColorAccent);
      changeRootThemeValues('--background-color', selectedTheme.properties.backgroundColor);
      changeRootThemeValues('--form-background-color', selectedTheme.properties.formBackgroundColor);
      changeRootThemeValues('--active-color', selectedTheme.properties.activeColor);
      changeRootThemeValues('--primary-text-color', selectedTheme.properties.primaryTextColor);
      changeRootThemeValues('--reversed-text-color', selectedTheme.properties.reversedTextColor);
      changeRootThemeValues('--special-text-color', selectedTheme.properties.specialTextColor);
      changeRootThemeValues('--muted-text-color', selectedTheme.properties.mutedTextColor);
      changeRootThemeValues('--home-background-url', `url(${homeScreenBackground})`);

    }
  }

  useEffect(() => {
    const storageAuthTokenValue = localStorage.getItem('authToken')
    applyTheme(localStorage.getItem('localThemeName'), localStorage.getItem('localShowBackgroundImages')!==null ? localStorage.getItem('localShowBackgroundImages') : false, localStorage.getItem('localbackgroundImage'));
    if (!storageAuthTokenValue) {
      navigate(FRONTEND_LOGIN_URL)
    }
    const fetchLykioContext = async () => {
      try {
        const { data } = await axios.get(
          `${AUTHORIZED_URL_PREFIX}${GET_CONTEXT}`,
          AxiosConfig(),
        )
        settenantProperties(data.tenantProperties);
        changeRootThemeValues('--home-background-url', `url(${data.tenantProperties.homeScreenBackground})`);
        setCategories(data.categories);
        setGroup(data.group);
        setUserDetails(data.userDetails);
        localStorage.setItem('user', JSON.stringify(data.userDetails));
        localStorage.setItem('userId', data.userDetails.id);
        setUserPermissions(data.permissions);
        localStorage.setItem(
          'userRole',
          data.userDetails.role,
        )
        let themeName;
        if (data.tenantProperties.forceRootTheme ){
           themeName = data.tenantProperties.rootTheme
        } else {
           themeName = (data.tenantProperties && data.tenantProperties.enableUsersCanPickTheme && data.userDetails.themeName) ? data.userDetails.themeName : data.tenantProperties.themeName;
        }
        let showBackgroundImages = data.tenantProperties && data.tenantProperties.showBackgroundImages;
        let backgroundImage = data.tenantProperties && data.tenantProperties.backgroundImage;
      
        localStorage.setItem(
          'localThemeName',
          themeName,
        )
        localStorage.setItem(
          'localShowBackgroundImages',
          showBackgroundImages,
        )
        localStorage.setItem(
          'localbackgroundImage',
          backgroundImage,
        )

        applyTheme(themeName, showBackgroundImages, backgroundImage);

        if (data.userDetails.settings) {
          data.userDetails.settings.systemLanguage &&
            localStorage.setItem(
              'i18n',
              data.userDetails.settings.systemLanguage,
            )
          data.userDetails.settings.contentLanguage &&
            localStorage.setItem(
              'content-i18n',
              data.userDetails.settings.contentLanguage,
            )
        }
      } catch (error) {
        localStorage.removeItem('authToken')
        navigate(FRONTEND_LOGIN_URL)
      }
    }

    fetchLykioContext()
  }, [])

  return localStorage.getItem('authToken') ? (
    <LykioContext.Provider
      value={{ userPermissions, categories, group, tenantProperties, userDetails }}
    >
      <React.Fragment>
		{/* <Navigation categories={categories} /> */}
    <MetaNavigation categories={categories} />
		{children}	 
	  </React.Fragment>
    </LykioContext.Provider>
  ) : (
    <Navigate to="/login" />
  )
}

export default LykioAuthorizedRoute
