import React from 'react'
import {Avatar, AvatarGroup, Conversation} from "@chatscope/chat-ui-kit-react";

const ConversationUserList = (props) => {



    return (
        <>
            {props.chat.isGroupChat ? (
                <Conversation
                    key={props.chat._id}
                    onClick= {() => props.handleSelectChat(props.chat)}
                    name={props.chat.chatName}
                    info={props.chat.latestMessage && props.chat.latestMessage.content}
                    unreadDot={props.getUnreadDot(props.chat)}
                    active={props.selectedChat === props.chat}>
                    <AvatarGroup size='sm' max={4} style={{
                        width: "42px",
                        height: "42px"
                    }}>
                        {props.chat.users.map((u) => {
                            return  (
                                <div key={u.email} as={Avatar}>
                                    <Avatar
                                        key={u.email}
                                        src={u.profilepicture}
                                        name={u.firstname ? u.firstname : u.email}
                                    />
                                </div>
                     )
                        })}
                    </AvatarGroup>
                </Conversation>
            ) : (
                <Conversation
                    key={props.chat._id}
                    onClick= {() => props.handleSelectChat(props.chat)}
                    name={props.sender.firstname? props.sender.firstname : props.sender.email}
                    lastSenderName={props.sender.firstname? props.sender.firstname : props.sender.email}
                    info={props.chat.latestMessage && props.chat.latestMessage.content}
                    unreadDot={props.getUnreadDot(props.chat)}
                    active={props.selectedChat === props.chat}>
                    <Avatar
                        src={props.sender.profilepicture}
                        name={props.sender.firstname ? props.sender.firstname : props.sender.email}
                        status="available"
                    />
                </Conversation>

            )}
        </>
    )
}

export default ConversationUserList;