const GDPR = (props) => {
    
    const supportEmail = props.supportEmail;

    return (<div>
    <h2>Platform GDPR Privacy Policy</h2>
    <p>The following privacy policy will explain how our organization uses the personal data we collect from you when you use our website.</p>
    <h3>1. What data do we collect?</h3>
    <p>The only mandatory piece of personal identification information is your email address. All other types of personal information are optional.</p>
    <p>The following non identifying information are also requested:</p>
    <ul>
        <li>a username and a password of your choice. It is always best to not use the same username and password as in other platforms to which you are registered.</li>
    </ul>
    <h3>2. How do we collect your data?</h3>
    <p>You directly provide the Platform with most of the data we collect. We collect all the data mentioned in topic (1) and store data when you use our registration page to create an account.</p>
    <p>Our platform also receives your saved game data indirectly (indirectly or directly) from the windows and os x versions of the complimentary game.</p>
    <h3>3. How will we use your data?</h3>
    <p>We collect your data only with the intent to grant you access to your content and the game. Your email and details will never be shared with any third parties.</p>
    <p>Your non identifying information will only be used for statistical reasons and for the evaluation and improvement of the platform.</p>
    <h3>4. How do we store your data?</h3>
    <p>Your data is securely stored using an Amazon Database Service located in Amazon’s european based data-centres.</p>
    <p>We will only keep your data for as long as the platform is active. Once the program expires, we will completely delete your data.</p>
    <h3>5. Marketing</h3>
    <p>Your data will never be used for any marketing reasons.</p>
    <h3>6. What are your data protection rights?</h3>
    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
    <ul>
        <li>The right to access – You have the right to request for copies of your personal data.</li>
        <li>The right to rectification – You have the right to request from us to correct any information you believe is inaccurate. You also have the right to request from us to complete the information you believe is incomplete.</li>
        <li>The right to deletion – You have the right to request from us to erase your personal data</li>
        <li>The right to restrict processing – You have the right to request from us to restrict the processing of your personal data (e.g. you may wish to keep your data on our databases but may request from us to set your account inactive/locked).</li>
        <li>The right to object to processing – You have the right to object to our processing of your personal data.</li>
        <li>The right to data portability – You have the right to request from us to transfer the data that we have collected directly to you.</li>
    </ul>
    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
    <h3>7. What are cookies?</h3>
    <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology. For further information, visit <a target="_blank" href="https://www.allaboutcookies.org/" shape="rect">allaboutcookies</a></p>
    <h3>8. What types of cookies do we use?</h3>
    <p>The only types of cookies-like technology our website uses are ones for your authorization and storing of your personal settings. Our platform uses these cookies so that we recognize your session on our website.</p>
    <h3>9. How to manage your cookies</h3>
    <p>You can set your browser not to accept cookies, and <a target="_blank" href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop" shape="rect">this resource</a> tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>
    <h3>10. Changes to our privacy policy</h3>
    <p>We keep our privacy policy under regular review and place any updates on this web page. This privacy policy was last updated on 28 Jul 2022.</p>
    <h3>How to contact us</h3>
    <p>If you have any questions about privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>
    <p>Email us at: <a href={`mailto:${supportEmail}`} shape="rect">{supportEmail}</a></p>
    </div>)
}

export default GDPR;