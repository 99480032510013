import React, {useContext} from 'react';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import './story.css';
import t from '../../Utils/Translation';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import timeSince from '../../Utils/TimeSince';
import UnitModal from './../common/UnitModal';
import VideoModal from './../common/VideoModal';
import Ratio from 'react-bootstrap/Ratio';
import {
  AUTHORIZED_URL_PREFIX,
  UNITOVERVIEW_URL_WITHOUT_PARAM,
  VIEW_UNIT_URL,
  UNITIFRAME_URL_WITHOUT_PARAM,
  LIBRARY_VIDEO,
  LIBRARY_AUDIO,
  LIBRARY_STUDENTBOOK,
  LIBRARY_TEACHERBOOK,
  NOTIFICATION_SEVERITY_INFO,
  AxiosConfig,
} from '../../Utils/Constants';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ActiveProfileImage from '../common/ActiveProfileImage';
import Tooltip from '@mui/material/Tooltip';
import CommentSharpIcon from '@mui/icons-material/CommentSharp';
import AccessAlarmSharpIcon from '@mui/icons-material/AccessAlarmSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import GradeSharpIcon from '@mui/icons-material/GradeSharp';
import StackedBarChartSharpIcon from '@mui/icons-material/StackedBarChartSharp';
import AudioFileSharpIcon from '@mui/icons-material/AudioFileSharp';
import { notify } from '../../Utils/Notificator';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';


const Story = (props) => {
  const context = useContext(LykioContext);
  const tenantProperties = context.tenantProperties;
  const navigate = useNavigate();
  const addableUnit = props.addableUnit;
  const hideStatistics = props.hideStatistics; 
  const hideOverviewPage = props.hideOverviewPage;
  // video modal
  const [showVideoModal, setShowVideoModal] = useState(false)
  const handleCloseVideoModal = () => setShowVideoModal(false)
  const handleShowVideoModal = async () => setShowVideoModal(true)

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = async () => {
    setShow(true)
    try {
      const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${VIEW_UNIT_URL}`,
        { unitId: props.story._id, progress: null, score: null },
        AxiosConfig(),
      )
      if (data.success === true) {
        console.log('Successfully added unit usage')
        props.story.usage++
      }
    } catch (error) {
      console.log('Oops, something went wrong with viewing the unit')
    }

    console.log("props.story",props.story)
  }

  const getParentCategory = (categoryreference)=>{
		let foundParentReference;
		if(tenantProperties && tenantProperties.categories){
			tenantProperties.categories.forEach((category) => {
		   	if(category.reference === categoryreference){
				foundParentReference = category.reference;
		   	}
		   	});
		   if(!foundParentReference){
			tenantProperties.categories.forEach((category) => {
				for(let i = 0; i < category.subCategories.length; i++){
					if(category.subCategories[i].reference === categoryreference){
						foundParentReference = category.reference;
					}
				}
			});
		   }
		}
		return foundParentReference;
	}

  const getFullCategoryName = (categoryreference)=>{
		let fullCategoryName;
		if(tenantProperties && tenantProperties.categories){
			tenantProperties.categories.forEach((category) => {
		   	if(category.reference === categoryreference){
				fullCategoryName = category.name;
        const re = /[^0-9](?=[0-9])/g;
        fullCategoryName = fullCategoryName.replace(re, '$& ');
		   	}
		   	});
		   if(!fullCategoryName){
			tenantProperties.categories.forEach((category) => {
				for(let i = 0; i < category.subCategories.length; i++){
					if(category.subCategories[i].reference === categoryreference){
						fullCategoryName = category.subCategories[i].name;
					}
				}
			});
		   }
		}
		return fullCategoryName;
	}

  return (
    <React.Fragment key={props.story._id}>
      <div onClick={() => {
        if(!addableUnit){
          if(hideOverviewPage){
            console.log("Got in the hideOverviewPage")
            if(props.story.categoryreference && props.story.categoryreference.toLowerCase().includes(LIBRARY_AUDIO.toLowerCase())){
              notify({
                title: `${t('Click on the audio icon to download the file')}`,
                severity: NOTIFICATION_SEVERITY_INFO,
                creationDate: '',
                })
            }
            else if(
              (props.story.categoryreference && props.story.categoryreference.toLowerCase().includes(LIBRARY_STUDENTBOOK.toLowerCase())) ||
              (props.story.categoryreference && props.story.categoryreference.toLowerCase().includes(LIBRARY_TEACHERBOOK.toLowerCase()))
            ){
              window.open(props.story.s3Url, '_blank');
            }
            else{
              navigate(`${UNITIFRAME_URL_WITHOUT_PARAM}${props.story._id}`)
            }
          }else{
            console.log("Got in the NOT hideOverviewPage")
            props.story.type.startsWith('Story') ? handleShow() : navigate(`${UNITOVERVIEW_URL_WITHOUT_PARAM}${props.story._id}`)}
          }
          
        }
        } className="lykiocss-story-item">
        {props.story.type === 'Story-Video' && (
          <Ratio aspectRatio="1x1">
            <div className="lykiocss-story-image">
              <video width="100%" height="100%">
                <source
                  src={`${props.story.s3Url}#t=1`}
                  type={props.story.mediatype}
                ></source>
              </video>
            </div>
          </Ratio>
        )}
        {props.story.type === 'Story-Image' && (
          <Ratio aspectRatio="1x1">
          <img
            src={props.story.s3Url}
            className="lykiocss-story-image"
            alt="story-cover"
          />
          </Ratio>
        )}
        {props.story.type.startsWith('System') && (
          <Ratio aspectRatio="1x1">
          <img
            src={props.story.coverS3Link}
            className="lykiocss-story-image"
            alt="story-cover"
          />
          </Ratio>
        )}
        <div className="lykiocss-story-item-type" />
        <div className="lykiocss-story-item-visible-info">
          <Container className='lykiocss-top-overlay'>
            <Row>
              <div className='lykiocss-story-item-title'>
                 {props.story.title==="Teacherbook" ? 'Teacher Book' : 
                 props.story.title==="Studentbook" ? 'Student Book' :
                 props.story.title}
              </div>
            </Row>
            {props.story.type.startsWith('Story') && (
            <Row>
            <div className='lykiocss-center lykiocss-story-item-category'>
                 {t("uploaded")} {timeSince(props.story.creationDate)}
              </div>
            </Row>
            )}
            {props.story.type.startsWith('System') && (
            <Row>
            <Tooltip title={t("category")}>
              <div className='lykiocss-center lykiocss-story-item-category lykiocss-text-shadow'>
                {(getParentCategory(props.story.categoryreference) === props.story.categoryreference) 
                ? getFullCategoryName(props.story.categoryreference) : 
                  `${getFullCategoryName(getParentCategory(props.story.categoryreference))}`
                } {props.story.completed ? <span>&nbsp;<CheckCircleOutlineSharpIcon/></span> : ''}
              </div>
            </Tooltip>
            </Row>
            )}
          </Container>
        </div>
        <div className="lykiocss-story-item-info">
        {addableUnit ? 
        <div>{props.children}</div> :
        <Container>
        {props.story.type.startsWith('Story') && (
        <Row>
          <div className='lykiocss-center'>
            <ActiveProfileImage user={props.story.uploader} doubleSize={true}/>
          </div>
        </Row>
        )}
        
        {props.story.type.startsWith('System') && (
          <Row>
          <div className='lykiocss-center lykiocss-story-description'>
          <Tooltip title={t("")}>
            <span>
            {props.story.description ? props.story.description : t('No available description')}
            </span>
          </Tooltip>
          </div>
          </Row>
        )}

        {hideOverviewPage && props.story.categoryreference && props.story.categoryreference.toLowerCase().includes(LIBRARY_AUDIO.toLowerCase()) && <div>
          <Row>
          <div className='lykiocss-center lykiocss-story-description' style={{fontSize:'4.5em'}}>
          <Tooltip title={t("Download the file")}>
          <a href={props.story.s3Url} ><AudioFileSharpIcon fontSize="inherit"/></a>
          </Tooltip>
          </div>
          </Row>
        </div>}
        <Row>
          <br/> <br/>
          <div className="d-flex justify-content-center lykiocss-margin-top-2em">
            {props.story.type.startsWith('System') && !hideStatistics && (
            <Tooltip title={`${props.story.duration} minutes long`}>
            <span>
            {props.story.duration}
            <AccessAlarmSharpIcon/><span>&nbsp;</span>
            </span>
            </Tooltip>)
            }

            {!hideStatistics &&
            <React.Fragment>
            <Tooltip title={`${props.story.comments ? props.story.comments.length : 0} comments`}>
            <span>
            {props.story.comments ? props.story.comments.length : 0}
            <CommentSharpIcon/><span>&nbsp;</span>
            </span>
            </Tooltip>

            <Tooltip title={`${props.story.ratings.length} times rated`}>
            <span>
              {props.story.ratings.length}
              <GradeSharpIcon/><span>&nbsp;</span>
            </span>
            </Tooltip>


            <Tooltip title={`${props.story.usage ? props.story.usage : 0} times viewed`}>
            <span>
              {props.story.usage ? props.story.usage : 0}
              <VisibilitySharpIcon/><span>&nbsp;</span>
            </span>
            </Tooltip>


            <Tooltip title={`${props.story.impactIndex} Impact Index`}>
            <span>
              {props.story.impactIndex}
              <StackedBarChartSharpIcon/><span>&nbsp;</span>
            </span>
            </Tooltip>
            </React.Fragment>
            }
          </div>
        </Row>
        {/* {props.story.type.startsWith('System') &&
        <Row>
        <div className='lykiocss-center lykiocss-story-goto'>
        <Tooltip title={`Go to ${props.story.title}`}>
            <span onClick={()=>{navigate(`${UNITOVERVIEW_URL_WITHOUT_PARAM}${props.story._id}`)}}>
              <ArrowCircleRightSharpIcon />
            </span>
        </Tooltip>
        </div>
        </Row>
        } */}
      </Container>
        }

        </div>
      </div>
      <UnitModal unit={props.story} show={show} handleClose={handleClose} />
      <VideoModal unit={props.story} show={showVideoModal} handleClose={handleCloseVideoModal} />
    </React.Fragment>
  )
}

export default Story
